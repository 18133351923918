import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Usage`}</h2>
    <Note variant="danger" mdxType="Note">
  <Text sx={{
        display: 'block',
        fontWeight: 'bold',
        mb: 2
      }} mdxType="Text">⚠️ Usage Warning ⚠️</Text>
  <Text mdxType="Text">Overlay is an internal component and is intended to be used as a private API, composing other components. It is not intended to be used on its own. For a ready-to-use overlay-type component, consider <a href="/components/dialog">Dialog</a>, <a href="/components/selectpanel">Select Panel</a>,  or <a href="/components/dialog">Action Menu</a>.</Text>
    </Note>
    <p>{`The overlay component handles all behaviors needed by overlay UIs as well as the common styles that all overlays should have. Overlay is the internal, or base component for many of our overlay-type components. Overlays use shadows to express elevation.`}</p>
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Overlay" mdxType="AccessibilityLink" />
    <h2>{`Related components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/anchored-overlay"
        }}>{`Anchored overlay`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      